import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ELFlogo } from "../../assets/ELF logo lueur.svg";
import "./Mob-Home.css";
import "../Home.scss";
import Particles from "react-particles-js";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { NavHashLink } from "react-router-hash-link";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Box from "@material-ui/core/Box";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import Fade from "@material-ui/core/Fade";
import SendIcon from "@material-ui/icons/Send";
import { makeStyles } from "@material-ui/core/styles";
import CodeRoundedIcon from "@mui/icons-material/CodeRounded";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import PublicIcon from "@mui/icons-material/Public";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import "reactjs-popup/dist/index.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
// import "react-vertical-timeline-component/style.min.css";
import "../VerticalTimelineElement.css";
import "../VerticalTimeline.css";

import * as emailjs from "emailjs-com";
import LottieAnimation from "../Lottie";
import ConsultingLottie from "../../animations/ConsultingLottie.json";
import AILottie from "../../animations/AILottie.json";
import WebLottie from "../../animations/WebLottie.json";
import MobileLottie from "../../animations/MobileLottie.json";
import TechAdvice from "../../animations/TechAdvice.json";
import RecruitmentLottie from "../../animations/RecruitmentLottie.json";
import useWindowDimensions from "../WindowDimensions";

// require("dotenv").config();
const { REACT_APP_TEMPLATE_ID } = process.env;

// const defaultTheme = createTheme({
//   palette: {
//     primary: {
//       main: "#ffff",
//     },
//     secondary: {
//       main: "#C5B358",
//     },
//   },

// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& > *": {
//       margin: theme.spacing(1),
//     },
//   },
// }));

const useStylesEmailContent = makeStyles((theme) => ({
  root: {
    fontSize: "1.2em",
    // border: "1px solid ",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "rgba(240,240,240,0.2)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "rgba(245,245,245,0.5)",
    },
    "&$focused": {
      backgroundColor: "rgba(245,245,245,0.5)",
      boxShadow: `rgba(150,150,150) 0 0 10px 2px`,
      // borderColor: "#C5B358",
    },
  },
  input: {
    // color: "#ffff",
    fontSize: "1.1em",
    fontWeight: "300",
    lineHeight: "1.1em",
  },
  focused: {},
}));

function EmailContent(props) {
  const classes = useStylesEmailContent();

  return (
    <TextField InputProps={{ classes, disableunderline: "true" }} {...props} />
  );
}

const useStylesInfo = makeStyles((theme) => ({
  root: {
    fontSize: "1.2em",
    // margin: "5%",
    // border: "1px solid ",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "rgba(240,240,240,0.2)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "rgba(245,245,245,0.5)",
    },
    "&$focused": {
      backgroundColor: "rgba(245,245,245,0.5)",
      boxShadow: `rgba(150,150,150) 0 0 10px 2px`,
      // borderColor: "#C5B358",
    },
  },
  input: {
    // color: "#ffff",
    fontWeight: "400",
  },
  focused: {},
}));

function Info(props) {
  const classes = useStylesInfo();

  return (
    <TextField InputProps={{ classes, disableunderline: "true" }} {...props} />
  );
}

// TABS

export default function MobHome() {
  // const [isDisabled, setDisabled] = useState(false);

  // const handleToggleGlitch = () => {
  //   setDisabled(!isDisabled);
  // };

  useEffect(() => {}, []);

  const [email, setEmail] = useState("");
  const [contactNumber, setNumber] = useState("");
  const [name, setName] = useState("");
  const [emailContent, setContent] = useState(
    "Hi, I'd like to discuss how you can help me building project. Thanks!"
  );
  const [processing, setProcessing] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [buttonState, setButtonState] = useState(true);

  const [emailCheck, setEmailCheck] = useState(false);
  const [numberCheck, setNumberCheck] = useState(false);
  const [nameCheck, setNameCheck] = useState(false);
  const [checkedState, handleSwitchCheck] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());

  // const classes = useStyles();
  function handleNumberCheck(Num) {
    if (!Number.isInteger(parseInt(Num.substr(1))) || Num.length <= 4) {
      return true;
    } else {
      return false;
    }
  }
  function handleEmailCheck(mail) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(mail).toLowerCase())) {
      return false;
    } else {
      return true;
    }
  }
  function handleNameCheck(nameInput) {
    if (nameInput === "") {
      return true;
    } else {
      return false;
    }
  }

  const handleSubmit = () => {
    // console.log(process.env.TEMPLATE_ID);
    // console.log(process.env.USER_ID);
    console.log(REACT_APP_TEMPLATE_ID);
    // e.preventDefault(); // Prevents default refresh by the browser
    const templateParams = {
      Name: name,
      About: "HACKYWAY",
      Email: email,
      Phone: contactNumber,
      message: selectedDate + "  --  " + emailContent,
    };
    emailjs
      .send(
        "service_ww5ozzo",
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          setMessageSent(true);
          setProcessing(false);
          setButtonState(false);
          // alert("Message Sent, We will get back to you shortly", result.text);
        },
        (error) => {
          alert("An error occurred, Please try again", error.text);
          setProcessing(false);
        }
      );
  };

  function checksChain(email, contactNumber, name) {
    if (handleEmailCheck(email) === true) {
      setEmailCheck(true);
    } else {
      setEmailCheck(false);
    }
    if (handleNumberCheck(contactNumber) === true) {
      setNumberCheck(true);
    } else {
      setNumberCheck(false);
    }
    if (handleNameCheck(name) === true) {
      setNameCheck(true);
    } else {
      setNameCheck(false);
    }
  }
  const size = useWindowDimensions();

  function handleSwitch() {
    if (checkedState) {
      handleSwitchCheck(false);
    } else {
      handleSwitchCheck(true);
    }
    console.log(checkedState);
  }

  return (
    <>
      <div className="Top-home">
        <ELFlogo className="logo" />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: "30px",
          }}
        >
          <div style={{ position: "relative", left: "-163px" }}>
            <div className="can-toggle demo-rebrand-1">
              <input id="d" type="checkbox" onClick={handleSwitch} />
              <label htmlFor="d">
                <div
                  style={{
                    border: "solid 1px white",
                    paddingBottom: "46px",
                    cursor: "pointer",
                  }}
                  // style={{ width: "500px" }}
                  className="can-toggle__switch"
                  data-checked="Consulting"
                  data-unchecked="Development"
                ></div>
              </label>
            </div>
          </div>
        </div>
        <div className="solutions">
          {!checkedState && (
            <div style={{ marginTop: "35px" }}>
              <div className="services">
                <h1
                  style={{
                    fontSize: "20px",
                    margin: "0 3% 4% 2%",
                    fontWeight: "300",
                    color: "rgb(100, 100, 100)",
                  }}
                >
                  <span
                    style={{
                      color: "rgb(33, 150, 243)",
                    }}
                  >
                    Our mission:
                  </span>{" "}
                  <span style={{ fontWeight: "500" }}>Reducing the costs</span>{" "}
                  of development for early-stage entrepreneurs.{" "}
                </h1>
                <div
                  className="services-wrapper"
                  style={{
                    marginTop: "40px",
                    marginBottom: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box clone mt={1}>
                    <div>
                      <LottieAnimation
                        lotti={MobileLottie}
                        height={size.width * (70 / 100)}
                        width={size.width * (70 / 100)}
                      />
                      <h2 style={{ fontSize: "20px" }}>Mobile Development</h2>
                      <p style={{ fontSize: "14px" }}>
                        Cross platform, highly scalable apps with all the
                        features you need. We offer packages tailored to
                        early-stage startups and the possibility to order an MVP
                        first.
                      </p>
                    </div>
                  </Box>
                  <Box clone mt={1}>
                    <div>
                      <LottieAnimation
                        lotti={WebLottie}
                        height={size.width * (40 / 100)}
                        width={size.width * (60 / 100)}
                      />
                      <h2 style={{ fontSize: "20px" }}>Web Development</h2>
                      <p style={{ fontSize: "14px" }}>
                        Full stack, highly responsive web apps usable seamlessly
                        no matter if your user is on a laptop, a conected car or
                        a smart fridge.
                      </p>
                    </div>
                  </Box>
                  <Box clone mt={2}>
                    <div>
                      <LottieAnimation
                        lotti={AILottie}
                        height={size.width * (50 / 100)}
                        width={size.width * (80 / 100)}
                      />
                      <h2 style={{ fontSize: "20px" }}>
                        AI & Machine Learning
                      </h2>
                      <p style={{ fontSize: "14px", marginBottom: "27px" }}>
                        High quality algorithms for projects that require
                        computer vision, smart recommendations, human language
                        processing or advanced data analytics.
                      </p>
                    </div>
                  </Box>
                </div>
                <NavHashLink
                  smooth
                  to="#contact"
                  style={{
                    backgroundColor: "rgb(33, 150, 243)",
                    color: "white",
                    padding: "6px 10px",
                    // marginTop: "50px",
                    // height: "fit-content",
                    marginBottom: "15px",
                    borderRadius: "10px",
                    textDecoration: "none",
                    boxShadow:
                      "8px 6px 10px rgba(93, 93, 93, 0.397), inset 16px 12px 15px rgba(255, 255, 255, 0.12)",
                    fontSize: "18px",
                  }}
                >
                  <span>GET STARTED</span>
                  <PlayArrowIcon
                    style={{
                      color: "white",
                      fontSize: "1.2em",
                      position: "relative",
                      top: "4px",
                    }}
                  />
                </NavHashLink>
              </div>
              <VerticalTimeline>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "rgb(33, 150, 243)",
                    color: "#fff",
                  }}
                  contentArrowStyle={{
                    borderRight: "7px solid  rgb(33, 150, 243)",
                  }}
                  // date="2011 - present"
                  iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                  icon={<PeopleAltOutlinedIcon />}
                >
                  <div
                    stly={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      className="vertical-timeline-element-title"
                      style={{
                        width: "fit-content",
                        fontSize: "20px",
                        fontWeight: "500",
                        // margin: "0 x 0 0",
                      }}
                    >
                      1st Meeting
                    </span>
                    <NavHashLink
                      smooth
                      to="#contact"
                      style={{
                        backgroundColor: "rgb(243,243,243",
                        color: "rgb(33, 150, 243)",
                        padding: "3px 11px 6px 11px",
                        height: "24px",
                        width: "100px",
                        marginBottom: "30px",
                        borderRadius: "15px",
                        textDecoration: "none",
                        boxShadow:
                          "8px 6px 10px rgba(93, 93, 93, 0.397), inset 16px 12px 15px rgba(255, 255, 255, 0.12)",
                        fontSize: "10px",
                        position: "absolute",
                        fontWeight: "600",
                        left: "35%",
                        bottom: "-20px",
                      }}
                    >
                      <span>BOOK NOW</span>
                      <PlayArrowIcon
                        style={{
                          color: "rgb(33, 150, 243)",
                          fontSize: "1.2em",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                    </NavHashLink>
                  </div>
                  <p>
                    • We speak about your project, vision and objectives <br />
                    • We outline what you need to be developed <br />• We answer
                    all your questions
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  // date="2010 - 2011"
                  iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                  icon={<ArchitectureIcon />}
                >
                  <h3
                    className="vertical-timeline-element-title"
                    style={{ fontSize: "20px", fontWeight: "500" }}
                  >
                    Tech Consultation
                  </h3>
                  <p>
                    • Get a report with:
                    <br />- the full tech stack we selected for your project
                    <br />
                    - a detailed development strategy and timeline
                    <br />- a quote for the development of your project
                    <br />
                    <br />• Then choose if you wish to develop an MVP first or
                    the full project directly
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  // date="2008 - 2010"
                  iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                  icon={<CodeRoundedIcon />}
                >
                  <span
                    className="vertical-timeline-element-title"
                    style={{
                      width: "fit-content",
                      fontSize: "20px",
                      fontWeight: "500",
                      margin: "2px",
                      // paddingBottom: "25px",
                      // margin: "0 x 0 0",
                    }}
                  >
                    Minimum Viable Product
                  </span>
                  <br />
                  <br />
                  <br />
                  <div
                    style={{
                      // backgroundColor: "rgb(243,243,243",
                      textAlign: "center",
                      justifyContent: "center",
                      border: "solid 1px rgb(33, 150, 243)",
                      color: "rgb(33, 150, 243)",
                      padding: "5px 14px 8px 14px",
                      height: "42px",
                      width: "100px",
                      borderRadius: "10px",
                      textDecoration: "none",
                      boxShadow:
                        "2px 1px 8px rgba(93, 93, 93, 0.397), inset 16px 12px 15px rgba(255, 255, 255, 0.9)",
                      fontSize: "10px",
                      position: "absolute",
                      fontWeight: "600",
                      left: "35%",
                      top: "44px",
                    }}
                  >
                    <span>
                      Only at
                      <br />
                      <span style={{ fontSize: "12px" }}>HackyWay</span>
                    </span>
                  </div>
                  <p style={{ marginTop: "18px" }}>
                    • A fraction of the full development's cost
                    <br />• Get it done much faster
                    <br />
                    • Use it to demonstrate your concept to potential investors
                    <br />• We will deduct the price should you ask us to
                    develop the full product later
                    <br />
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  // date="2006 - 2008"
                  iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                  icon={<PublicIcon />}
                >
                  <h3
                    className="vertical-timeline-element-title"
                    style={{ fontSize: "20px", fontWeight: "500" }}
                  >
                    Full Development
                  </h3>
                  <p>
                    • We develop, or finish developing your entire product
                    <br />• Get all the features ready for deployment
                    <br />• Get a scalable app ready to be used by millions !
                    <br />
                  </p>
                </VerticalTimelineElement>
              </VerticalTimeline>
              <div id="contact" className="contact-card-mob">
                <h1
                  className="contact-us"
                  style={{
                    fontSize: "23px",
                    textAlign: "center",
                    padding: "4% 5% 5% 5%",
                  }}
                >
                  Contact us and
                  <br />
                  get started now !
                </h1>
                {/* <form onSubmit={}> */}
                <Box clone>
                  <Info
                    className="info-input"
                    m={100}
                    style={{ textAlign: "left" }}
                    placeholder="Name"
                    id="outlined-multiline-flexible"
                    maxRows={1}
                    // value={value}
                    onChange={(e) => setName(e.target.value)}
                    error={nameCheck === true}
                    helperText={nameCheck ? "Field is empty !" : " "}
                    variant="outlined"
                  />
                </Box>
                <Box clone>
                  <Info
                    className="info-input"
                    m={100}
                    style={{ textAlign: "left" }}
                    placeholder="E-mail"
                    id="outlined-multiline-flexible"
                    maxRows={1}
                    // value={value}
                    onChange={(e) => setEmail(e.target.value)}
                    error={emailCheck === true}
                    helperText={emailCheck ? "Please enter a valid email" : " "}
                    variant="outlined"
                  />
                </Box>
                <Box clone>
                  <Info
                    className="info-input"
                    style={{ textAlign: "left" }}
                    placeholder="Contact number"
                    id="outlined-multiline-flexible"
                    maxRows={1}
                    // value={value}
                    onChange={(e) => setNumber(e.target.value)}
                    error={numberCheck === true}
                    helperText={
                      numberCheck ? "Please enter a valid number" : " "
                    }
                    variant="outlined"
                  />
                </Box>
                <Box clone>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className="info-input"
                      style={{ marginBottom: "15px", width: "276px" }}
                      autoOk
                      variant="outlined"
                      inputVariant="outlined"
                      label="Prefered date for meeting"
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      InputAdornmentProps={{ position: "start" }}
                      onChange={(date) => handleDateChange(date)}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
                <EmailContent
                  style={{ textAlign: "left" }}
                  InputLabelProps={{
                    style: {
                      textOverflow: "ellipsis",
                      fontSize: "1.05em",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "70%",
                      // marginRight: "100px",
                      // color: "#fff",
                    },
                  }}
                  fullWidth
                  multiline
                  minRows={6}
                  id="outlined-multiline-flexible"
                  maxRows={100}
                  defaultValue={emailContent}
                  onChange={(e) => setContent(e.target.value)}
                  error={emailContent === ""}
                  helperText={emailContent === "" ? "Empty field!" : " "}
                  variant="outlined"
                />
                <Box className="button-send">
                  {processing && <CircularProgress color="secondary" />}
                  {messageSent && (
                    <Fade in={messageSent}>
                      <CheckCircleOutlineRoundedIcon
                        style={{
                          color: "#00E21D",
                          fontSize: "3em",
                        }}
                      />
                    </Fade>
                  )}
                  {processing === false && buttonState && (
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        fontSize: "17px",
                        color: "white",
                        backgroundColor: "rgb(33, 150, 243)",
                      }}
                      onClick={async () => {
                        // console.log(new Date());
                        // handleEmailCheck(email);
                        if (
                          handleNumberCheck(contactNumber) === false &&
                          handleEmailCheck(email) === false &&
                          emailContent !== "" &&
                          handleNameCheck(name) === false
                        ) {
                          setProcessing(true);
                          handleSubmit();
                        }
                        checksChain(email, contactNumber, name);
                      }}
                    >
                      Send
                      <Box ml={1} mt={0} clone>
                        <SendIcon
                          style={{
                            color: "white",
                            fontSize: "1.2em",
                            // margin: "0 0 0 4px",
                          }}
                        />
                      </Box>
                    </Button>
                  )}
                </Box>
                <p
                  style={{
                    fontSize: "11px",
                    color: "rgb(100,100,100)",
                    position: "relative",
                    // top: "-20px",
                  }}
                >
                  We will use this information to get back to you and arrange a
                  call/meeting to discuss your project.
                  <br /> We DO NOT share this information with third parties.
                  (See our{" "}
                  <Link to="/privacy-policy" style={{ color: "#4b4b4b" }}>
                    Privacy Policy
                  </Link>
                  ).
                </p>
              </div>
            </div>
          )}
          {checkedState && (
            <div>
              <h1
                style={{
                  fontSize: "20px",
                  margin: "10% 5% 6% 5%",
                  fontWeight: "300",
                  color: "rgb(100, 100, 100)",
                }}
              >
                <span style={{ color: "rgb(33, 150, 243)", fontWeight: "300" }}>
                  Consulting services:
                </span>{" "}
                We also provide advice and guidance in a variety of fields that
                can benefit your startup:{" "}
              </h1>
              <div
                className="services-wrapper"
                style={{
                  marginBottom: "18px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box clone mt={2}>
                  <div>
                    <LottieAnimation
                      lotti={TechAdvice}
                      height={size.width * (60 / 100)}
                      width={size.width * (70 / 100)}
                    />
                    <h2>Technology</h2>
                    <p>
                      Besides offering development services, we can also prepare
                      a full strategy for your business with advice on which
                      technologies you should use and the best practices should
                      you decide to develop the product internally.
                    </p>
                  </div>
                </Box>
                <Box clone mt={1}>
                  <div>
                    <LottieAnimation
                      lotti={ConsultingLottie}
                      height={size.width * (60 / 100)}
                      width={size.width * (70 / 100)}
                    />
                    <h2>Finance</h2>
                    <p>
                      We can help you prepare your finances, business plans and
                      define a strategy to raise funds. We will use our
                      experience in VC investment analysis to give you the best
                      advice to attract new investors.
                    </p>
                  </div>
                </Box>
                <Box clone mt={2.5}>
                  <div>
                    <LottieAnimation
                      lotti={RecruitmentLottie}
                      height={size.width * (40 / 100)}
                      width={size.width * (80 / 100)}
                    />
                    <h2>Recruitment</h2>
                    <p>
                      The developers job market is one of the most difficult for
                      companies to hire in. We will use our network to find you
                      the most talented and motivated available developers so
                      you can make them an offer.
                    </p>
                  </div>
                </Box>
              </div>
              <div className="contact-card-mob" style={{ marginTop: "25px" }}>
                <h1
                  className="contact-us"
                  style={{
                    fontSize: "23px",
                    textAlign: "center",
                    padding: "4% 5% 5% 5%",
                  }}
                >
                  Contact us and
                  <br />
                  get started now !
                </h1>
                {/* <form onSubmit={}> */}
                <Box clone>
                  <Info
                    className="info-input"
                    m={100}
                    style={{ textAlign: "left" }}
                    placeholder="Name"
                    id="outlined-multiline-flexible"
                    maxRows={1}
                    // value={value}
                    onChange={(e) => setName(e.target.value)}
                    error={nameCheck === true}
                    helperText={nameCheck ? "Field is empty !" : " "}
                    variant="outlined"
                  />
                </Box>
                <Box clone>
                  <Info
                    className="info-input"
                    m={100}
                    style={{ textAlign: "left" }}
                    placeholder="E-mail"
                    id="outlined-multiline-flexible"
                    maxRows={1}
                    // value={value}
                    onChange={(e) => setEmail(e.target.value)}
                    error={emailCheck === true}
                    helperText={emailCheck ? "Please enter a valid email" : " "}
                    variant="outlined"
                  />
                </Box>
                <Box clone>
                  <Info
                    className="info-input"
                    style={{ textAlign: "left" }}
                    placeholder="Contact number"
                    id="outlined-multiline-flexible"
                    maxRows={1}
                    // value={value}
                    onChange={(e) => setNumber(e.target.value)}
                    error={numberCheck === true}
                    helperText={
                      numberCheck ? "Please enter a valid number" : " "
                    }
                    variant="outlined"
                  />
                </Box>
                <Box clone>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className="info-input"
                      style={{ marginBottom: "15px", width: "273px" }}
                      autoOk
                      variant="outlined"
                      inputVariant="outlined"
                      label="Prefered date for meeting"
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      InputAdornmentProps={{ position: "start" }}
                      onChange={(date) => handleDateChange(date)}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
                <EmailContent
                  style={{ textAlign: "left" }}
                  InputLabelProps={{
                    style: {
                      textOverflow: "ellipsis",
                      fontSize: "1.05em",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "70%",
                      // marginRight: "100px",
                      // color: "#fff",
                    },
                  }}
                  fullWidth
                  multiline
                  minRows={6}
                  id="outlined-multiline-flexible"
                  maxRows={100}
                  defaultValue={emailContent}
                  onChange={(e) => setContent(e.target.value)}
                  error={emailContent === ""}
                  helperText={emailContent === "" ? "Empty field!" : " "}
                  variant="outlined"
                />
                <Box className="button-send">
                  {processing && <CircularProgress color="secondary" />}
                  {messageSent && (
                    <Fade in={messageSent}>
                      <CheckCircleOutlineRoundedIcon
                        style={{
                          color: "#00E21D",
                          fontSize: "3em",
                        }}
                      />
                    </Fade>
                  )}
                  {processing === false && buttonState && (
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        fontSize: "17px",
                        color: "white",
                        backgroundColor: "rgb(33, 150, 243)",
                      }}
                      onClick={async () => {
                        // console.log(new Date());
                        // handleEmailCheck(email);
                        if (
                          handleNumberCheck(contactNumber) === false &&
                          handleEmailCheck(email) === false &&
                          emailContent !== "" &&
                          handleNameCheck(name) === false
                        ) {
                          setProcessing(true);
                          handleSubmit();
                        }
                        checksChain(email, contactNumber, name);
                      }}
                    >
                      Send
                      <Box ml={1} mt={0} clone>
                        <SendIcon
                          style={{
                            color: "white",
                            fontSize: "1.2em",
                            // margin: "0 0 0 4px",
                          }}
                        />
                      </Box>
                    </Button>
                  )}
                </Box>
                <p
                  style={{
                    fontSize: "11px",
                    color: "rgb(100,100,100)",
                    position: "relative",
                    // top: "-20px",
                  }}
                >
                  We will use this information to get back to you and arrange a
                  call/meeting to discuss your project.
                  <br /> We DO NOT share this information with third parties.
                  (See our{" "}
                  <Link to="/privacy-policy" style={{ color: "#4b4b4b" }}>
                    Privacy Policy
                  </Link>
                  ).
                </p>
              </div>
            </div>
          )}

          {/* CONSULTING */}
          {/* <Box
          sx={{
            width: "100%",
            bgcolor: "#1a1a1a",
            textColor: "#FFFF",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "fit-content",
            }}
          >
            <Tabs
              value={value}
              onChange={handleNewTab}
              centered
              sx={{ border: "solid 1px white", borderRadius: "10px" }}
            >
              <Tab
                label=<React.Fragment>
                  <span style={{ backgroundColor: "white", width: "100%" }}>
                    T1 Label2
                  </span>
                </React.Fragment>
                sx={{ color: "white" }}
              />
              <Tab label="Item One" sx={{ color: "white" }} />
            </Tabs>
          </Box>
        </Box> */}
          {/* <ReactTypingEffect
          text={["Tech that focuses on your Business."]}
          cursorRenderer={(cursor) => <h1>{cursor}</h1>}
          displayTextRenderer={(text, i) => {
            return <h1>{text}</h1>;
          }}
          className="header"
          speed="100"
        /> */}
          {/* <div className="arrow bounce">
          <HashLink
            smooth
            className="fa fa-arrow-down fa-2x"
            to={{ hash: "#Mission-content" }}
          >
            <img className="ImgArrow" src={arrowdown} alt="arrow down"></img>
          </HashLink>
        </div> */}

          {/* <Particles
            canvasClassName="particles-mob"
            params={{
              particles: {
                number: {
                  value: 100,
                  density: {
                    enable: false,
                  },
                },
                size: {
                  value: 2,
                  random: true,
                  anim: {
                    speed: 4,
                    size_min: 0.3,
                  },
                },
                line_linked: {
                  enable: false,
                },
                move: {
                  random: true,
                  speed: 1,
                  direction: "top",
                  out_mode: "out",
                },
              },
              interactivity: {
                events: {
                  onhover: {
                    enable: true,
                    mode: "bubble",
                  },
                  onclick: {
                    enable: true,
                    mode: "repulse",
                  },
                },
                modes: {
                  bubble: {
                    distance: 250,
                    duration: 2,
                    size: 0,
                    opacity: 0,
                  },
                  repulse: {
                    distance: 400,
                    duration: 4,
                  },
                },
              },
            }}
          /> */}

          <Particles
            params={{
              particles: {
                number: {
                  value: 20,
                },
                size: {
                  value: 1,
                },
                line_linked: {
                  shadow: {
                    enable: true,
                    color: "#3CA9D1",
                    blur: 16,
                  },
                },
              },
            }}
            width={"800"}
            // height={"900"}
            canvasClassName="particles-mob"
          />

          <div
            style={{ position: "relative", fontSize: "11px" }}
            className="footer"
          >
            <p>"Nothing endures but change." – Heraclitus</p>
            <p>
              HackyWay is a service offered by{" "}
              <Link to="/ELF">ELF Technologies</Link>
            </p>
            <p>info@elf-technologies.io</p>
            <p className="company-info">
              ELF Technologies Limited is a company registered in England and
              Wales (Company No. 13459388) 65 London Wall London EC2M 5TU <br />
              <br />
            </p>
          </div>
        </div>
      </div>

      <script src="particles.js"></script>
    </>
  );
}
